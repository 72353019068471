<template>
  <div>
    <div class="col-12">
      <p class="larger mb-2"><b>{{ filterItem.name }}</b></p>
      <span class="vform-label white medium">{{ $t('email') }}</span>
      <info-helper class="ml-1" text="stepEmailDisclaimer"/>
      <input v-model="email"
             :class="['form-text form-text-dark w-100 mb-3', $v.email.$error ? 'form-group--error' : '']"
             type="text"
             @blur="$v.email.$touch"
      >
      <div v-if="!$v.email.email" class="form-error mb-2 border-radius">{{ $t('errors.mustBeAValidEmailAdress') }}</div>
      <info-panel
          :collapsible="true"
          :initially-collapsed="true"
          class="border-panel small-header smaller-title new-style mt-3"
          info-panel-header="SourceCode" preview-id="something">
        <div slot="info">
          <pre>{{ content }}</pre>
        </div>
      </info-panel>
      <info-panel :collapsible="true" :initially-collapsed="true"
                  class="border-panel light-header smaller-title small-header new-style" info-panel-header="AdvancedFeatures"
                  preview-id="something">
        <div slot="info" class="bg-highlight d-inline-block p-2 mb-3 border-radius">
          <icon type="exclamation-triangle"/>
          {{ $t('keepOriginalNamesAlert') }}
        </div>
        <div slot="info" class="form-group">
          <input id="checkbox" ref="checkbox" v-model="renaming" class="checkbox float-left mr-2"
                 type="checkbox">
          <div class="ml-4 inline-edit-label" for="checkbox">{{ $t('keepOriginalNames') }}</div>
        </div>
      </info-panel>
      <filter-display style="background-color: rgba(255,255,255,0.1)" class="p-2 mb-2 border-radius" v-if="content" :content="content" />

    </div>
    <vm-selector :show-prices="$store.getters.isSuperAdmin" class="mb-3 col-12 col-md-6" @selected="setVM"/>
    <div v-if="error" class="form-error ml-3 col-12 col-md-6 border-radius">{{ error }}</div>
    <div v-if="!processing" class="col-12">
      <div class="settings-button d-inline-block" @click="$emit('abort')">{{ $t('abort') }}
        <icon class="ml-3" type="times"/>
      </div>
      <div class="settings-button d-inline-block icon-rotate-animation make-longer-animation highlight ml-3"
           @click="triggerVersionProcess">
        {{ $t('start') }}
        <icon class="ml-3" type="cube"/>
      </div>
    </div>
    <div v-if="processing" class="col-12 mt-1">
      <loading-panel :time-in-seconds="0" message="pleaseWait"/>
    </div>
  </div>
</template>

<script>
import LoadingPanel from "@/components/LoadingPanel";
import Icon from "../Icon";
import InfoPanel from "@/components/InfoPanel";
import {email} from "vuelidate/lib/validators";
import InfoHelper from "@/components/InfoHelper";
import vmSelector from "@/components/vmSelector";
import FilterDisplay from "../exportProfiles/FilterDisplay";
export default {
  name: "FilterTriggerForm",
  components: {
    LoadingPanel,
    Icon,
    InfoPanel,
    InfoHelper,
    vmSelector,
    FilterDisplay
  },
  props: {
    /*
    * @param filterItem {Object} a filter-instance, needs to contain the asset as an include
    * */
    filterItem: {type: Object, required: true},
    projectId: {type: String, required: true},
    rootNode: {type: String, required: true},
    instanceId: {type: String, default: ""},
    inputUnits: {type: String, default: null}
  },
  data() {
    return {
      assetContent: {},
      content: {},
      mode: 'exclude',
      error: '',
      processing: false,
      email: this.$store.getters.getUserEmail(),
      renaming: false,
      vm: '',
    };
  },
  validations: {
    email: {
      email,
    },
  },
  beforeMount() {
    this.assetContent = this.filterItem.content ? JSON.parse(this.filterItem.content) : {};
    this.content = this.assetContent;
  },
  methods: {
    setVM(id) {
      this.vm = id;
    },
    async getLodFiles() {
      return await this.$store.dispatch('loadInstanceFiles', {id: this.rootNode})
          .then(files => {
            const fbx = files.filter(item => {
              return item.key.includes('.fbx') && item.key !== 'skel.fbx'
            });
            let res = [];
            if (fbx.length) {
              res = fbx.map(item => {
                return `${this.rootNode}/${item.key}`;
              });
            }
            return res;
          })
    },
    async deleteCache() {
      await this.$store.dispatch('clientDeleteAssemblyProjectInstancesCaching', { id: this.projectId });
      this.$store.dispatch('createNotification', {
        text: 'Cache deleted',
      })
      console.log('cache deleted');
    },
    async triggerVersionProcess() {
      this.processing = true;
      const files = await this.getLodFiles();
      // eslint-disable-next-line no-unreachable
      if (!this.rootNode) {
        this.error = this.$t('errors.rootNodeNotFound');
      }
      const {pipeline, mode, filter, filterStringArray, merge, mergeStringArray} = this.content;
      const {axisMultiplier, pivotMetaSetId, pivotPlacement, pivotField} = pipeline;
      let config = {
        axisMultiplier,
        pivotPlacement,
        pivotField,
        pivotMetaSetId,
        mode,
        filter,
        filterStringArray,
        merge,
        mergeStringArray,
        sendMails: true,
        email: this.email ? this.email : this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
        lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        files: files,
        rootNodeId: this.rootNode,
        renaming: this.renaming,
        outputFormats: ['fbx', 'glb'],
        inputUnits: this.inputUnits
      };
      // legacy fix
      if (!config.mode) {
        config.mode = this.content.pipeline.subVersion.mode;
      }
      const method = this.instanceId ? 'updateFilterInstance' : 'createFilterInstance';
      const fixedContent = JSON.stringify({
        ...this.content,
        ...config
      });
      let args = {
        id: this.projectId,
        state: 'p',
        name: this.filterItem.name + '_export',
        args: [{
          assetId: this.filterItem.id,
          content: fixedContent
        }]
      }
      if (this.instanceId) {
        args.cid = this.instanceId;
        args.content = fixedContent;
      }
      await this.$store.dispatch(method, args)
          .then(async instance => {
            if (instance) {
              await this.deleteCache();
              const selected = instance[0] ? instance[0] : instance;
              await this.$store.dispatch('clientInstanceToPipeline', {
                id: selected.id,
                type: 'filter',
                virtualMachine: this.vm,
                config: JSON.stringify(config)
              }).then(() => {
                this.processing = false;
                this.$emit('started');
              }).catch(e => {
                this.error = e.response.errors.message;
                this.processing = false;
              });
            }
          });
    },
  }
}
</script>

<style scoped>
pre {
  color: #fff;
  word-break: break-all;
}

</style>